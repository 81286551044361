<template>
    <div class="container">
        <h1>Carte du monde</h1>

        <h2>En construction !</h2>
    </div>
</template>

<script>
export default {
    name: 'Map'
}
</script>
